<template>
  <div style="display: flex; align-items: center; flex-direction: column">
    <v-card
      width="850px"
      max-width="95%"
      style="
        background-color: var(--dark);
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
      "
      ><h1
        class="h5 pa-5"
        style="color: var(--white); display: flex; justify-content: center"
      >
        <b>{{ $tc("edited_field", 2) }}</b>
      </h1>
    </v-card>

    <v-card
      width="850px"
      :key="pluss"
      style="
        overflow-y: scroll;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      "
      max-width="95%"
      max-height="500px"
    >
      <v-row class="ma-0">
        <v-col cols="6" style="display: flex; justify-content: center">
          <span style="font-size: 20px; font-weight: bold">{{
            $t("before-edit")
          }}</span>
        </v-col>
        <v-col cols="6" style="display: flex; justify-content: center">
          <span style="font-size: 20px; font-weight: bold">{{
            $t("after-edit")
          }}</span>
        </v-col>
      </v-row>
      <v-row v-for="(item, name) in this.beforeEdit" :key="name" class="ma-0">
        <v-col
          cols="6"
          v-if="item != afterEdit[name] && !_.isEqual(item, afterEdit[name])"
        >
          <ul>
            <li>
              <b>{{ name }}</b
              >: {{ item == null || item == "" ? $t("no-register") : item }}
            </li>
          </ul>
        </v-col>
        <v-col
          cols="6"
          v-if="item != afterEdit[name] && !_.isEqual(item, afterEdit[name])"
        >
          <ul>
            <li>
              <b>{{ name }}</b
              >:
              {{
                afterEdit[name] == null || afterEdit[name] == ""
                  ? $t("no-register")
                  : afterEdit[name]
              }}
            </li>
          </ul>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>
<script>
import GeneralServices from "@/services/GeneralServices";
export default {
  name: "ViewChangesModal",
  data() {
    return {
      gs: new GeneralServices(),
      afterEdit: null,
      beforeEdit: null,
      interception: [],
      pluss: 0,
    };
  },
  computed: {},
  props: {
    recorder: Object,
    plus: Number,
  },
  created() {
    this.pluss = this.plus;
    this.jsonRevert();
  },
  methods: {
    close() {
      this.$emit("closeModal");
    },
    jsonRevert() {
      this.afterEdit = JSON.parse(this.recorder.afterEdit);
      this.beforeEdit = JSON.parse(this.recorder.beforeEdit);
    },
  },
};
</script>

<style scoped></style>
