<template>
  <div>
    <v-container :key="counter" id="interest-table" fluid tag="section">
      <base-material-card inline color="secondary" class="px-5 py-3 mb-5">
        <template v-slot:heading><v-icon>mdi-database-edit</v-icon></template>
        <template v-slot:after-heading
          ><h1 class="h5">
            <b>{{ $t("record-edit") }}</b>
          </h1></template
        >
        <v-form
          @submit.stop.prevent="apply_filters()"
          ref="form"
          lazy-validation
        >
          <v-row align="center" no-gutters class="my-4">
            <v-text-field
              class="mr-2"
              hide-details
              outlined
              dense
              v-model="edited_filter"
              :label="$t('who-was-edited')"
            />
            <v-text-field
              class="mx-2"
              hide-details
              outlined
              dense
              v-model="edit_filter"
              :label="$t('who-edited')"
            />
            <v-btn class="dxa-btn-secondary mx-2" @click="clean_filters">
              {{ $t("clean_filters") }}
            </v-btn>
            <v-btn class="dxa-btn-primary ml-2" type="submit">
              {{ $t("apply") }}
            </v-btn>
          </v-row>
        </v-form>
        <div v-if="!loading">
          <v-simple-table>
            <thead>
              <tr>
                <th>{{ $t("who-was-edited") }}</th>
                <th>{{ $t("who-edited") }}</th>
                <th>{{ $t("date-hour") }}</th>
                <th>{{ $t("details") }}</th>
              </tr>
            </thead>

            <tbody>
              <tr v-for="(item, index) in records" :key="index">
                <!-- <td>{{ item.UserEmail }}</td>
                <td>{{ item.Body }}</td>-->
                <td>{{ item.nameOfWhoWasEdited }}</td>
                <td>{{ item.nameOfWhomEdited }}</td>
                <td>{{ item.editDate | format }}</td>
                <td>
                  <v-btn @click="openDialog(item)" color="secondary" small
                    ><v-icon>mdi-eye</v-icon></v-btn
                  >
                </td>
                -
              </tr>
            </tbody>
          </v-simple-table>
          <v-dialog v-model="dialog"
            ><ViewChangesModal
              v-bind:key="key"
              @closeModal="close"
              :recorder="item_prop"
            ></ViewChangesModal
          ></v-dialog>
          <v-pagination
            v-model="page"
            @input="change_page"
            :length="total_pages"
            color="secondary"
          >
          </v-pagination>
        </div>
        <v-container v-else>
          <v-progress-circular
            style="margin-left: 50%"
            indeterminate
            size="70"
            color="primary"
          ></v-progress-circular>
        </v-container>
      </base-material-card>
    </v-container>
  </div>
</template>

<script>
import ApiService from "@/services/ApiService";
import GeneralServices from "@/services/GeneralServices";
import moment from "moment";
import ViewChangesModal from "./ViewChangesModal.vue";

export default {
  name: "RecordEditRegister",

  components: {
    ViewChangesModal,
  },
  filters: {
    format(date) {
      return moment(date).format("DD/MM/YYYY - hh:mm a");
    },
  },

  data: () => ({
    gs: new GeneralServices(),
    apiService: new ApiService(),
    loading: false,
    page: 1,
    records: null,
    valid: true,
    lazy: false,
    total_pages: 0,
    dialog: false,
    afterEdit: null,
    item_prop: [],
    edit_filter: null,
    edited_filter: null,
    key: 0,
  }),
  props: {},
  async created() {
    await this.get_records_by_page();
  },
  computed: {},
  methods: {
    clean_filters() {
      this.edit_filter = null;
      this.edited_filter = null;
      this.apply_filters();
    },
    apply_filters() {
      this.page = 1;
      this.change_page();
    },
    async get_records_by_page() {
      this.loading = true;
      var page = this.page;
      if (this.$route.query.page) {
        page = parseInt(this.$route.query.page);
      }
      var params = {
        Page: page,
        EditName: this.edit_filter,
        EditedName: this.edited_filter,
      };
      await this.apiService
        .getRequest("recordedit/get-edited-users", params)
        .then((resp) => {
          let content = resp.content;
          this.page = content.pager.currentPage;
          this.total_pages = content.pager.totalPages;
          this.records = content.records;
        });
      this.loading = false;
    },
    change_page() {
      this.$router.replace({
        path: "/record-edit-register",
        query: { page: this.page },
      });
      this.get_records_by_page();
    },
    close() {
      this.dialog = false;
    },
    openDialog(item) {
      this.key++;
      this.dialog = !this.dialog;
      this.item_prop = item;
    },
  },
};
</script>
